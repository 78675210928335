@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --tablet: 768px;
  --smallMonitor: 992px;
  --largeMonitor: 1200px;
  --font-family: "Poppins", sans-serif;

  --color-contractor: #aab6c1;
  --color-propcomp: #6c9581;

  --color-sjogras: #c9e3dd;
  --color-fogmassa: #f7f5f1;
  --color-fogmassa-light: #f2f2f2;
  --color-cactus: #639780;
  --color-butelj: #134b3d;
  --color-white: white;
  --color-grey: #b9b7b3;
  --color-korall: #dd7566;
  --color-ockra: #96652a;
  --color-ockra-light: #e5ccae;
  --color-skog: #456959;
  --color-skog-light: #c1d5cc;
  --color-grafit: #555b56;
  --color-grafit-light: #c5c8c5;
  --color-tegel: #663a30;
  --color-tegel-light: #d0b8b3;
  --color-ametist: #594d5b;
  --color-ametist-light: #bdb8be;
  --color-safir: #2b4863;
  --color-safir-light: #aab6c1;
  --color-mossa: #655b00;
  --color-mossa-light: #c1bd99;
  --color-rubin: #670f2f;
  --color-rubin-light: #c29fac;
  --color-shadow: #bbbbbb;
  --color-shadow-light: #555555;
  --color-sandsten: #efb492;
  --color-sandsten-light: #f7e8d5;
  --color-blekros: #cbbda8;
  --color-blekros-light: #e8e1d9;

  --color-period-now: #639780;
  --color-period-previous: #aab6c1;
}

body {
  font-family: var(--font-family) !important;
  background-color: var(--color-fogmassa-light);
  height: auto !important;
}

body ::-webkit-scrollbar {
  width: 6px;
}

a {
  color: var(--color-cactus);
}

a:hover {
  text-decoration: underline;
  color: var(--color-butelj);
}

.text-link {
  font-weight: normal;
}

/* unvisited link */
.text-link :link {
  color: rgba(0, 0, 0, 1);
}

/* visited link */
.text-link :visited {
  color: rgb(88, 89, 95);
}

/* mouse over link */
.text-link :hover {
  color: var(--color-butelj);
}

/* selected link */
.text-link :active {
  color: var(--color-butelj);
}

.ui.vertical.menu.sidebar-menu {
  margin-top: 40px !important;
  max-height: calc(100% - 40px) !important;
  height: calc(100% - 40px) !important;
  z-index: 7;
  overflow-x: hidden;
}

.ui.vertical.menu.sidebar-menu a.item {
  font-size: 15px;
}

.ui.vertical.menu.sidebar-menu .item i.icon {
  float: left;
  margin: 0em 0.5em 0em 0em;
}

.main-content {
  margin-top: 60px;
  padding-bottom: 30px;
}

.ui.vertical.menu.sidebar-menu-mobile {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 15rem !important;
}

.context-menu {
  border: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
  border: 0 !important;
}

.context-menu a i.icon {
  color: var(--color-cactus) !important;
}

.text-no-wrap {
  text-wrap: nowrap;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.centerVertical {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flexboxSpacedBetween {
  display: flex;
  justify-content: space-between;
}

.companyTitleHeader {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}

.font-size-38 {
  font-size: 38px;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.margin-bottom-14 {
  margin-bottom: 14px;
}

.inlineBlock {
  display: inline-block;
}

.displayUnset {
  display: unset !important;
}

.formLabelShort {
  display: inline-block;
  width: 70px;
  text-align: left;
  font-weight: 600;
}

.formLabel {
  display: inline-block;
  width: 140px;
  text-align: left;
  font-weight: 600;
}

.formLabelWide {
  display: inline-block;
  width: 240px;
  text-align: left;
  font-weight: 600;
}

.formInputShort {
  width: 132px !important;
}

.bold {
  font-weight: 600 !important;
}

.italic {
  font-style: italic !important;
}

.ratingCommentResponse {
  background-color: #fff;
  margin-left: 2em !important;
  border-radius: 18px;
  flex: auto;
}

.ratingCommentResponseIcon {
  transform: rotate(45deg);
  color: var(--color-butelj);
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.overflowAuto {
  overflow: "auto";
}

.cursorPointer {
  cursor: pointer;
}

.arrowPointer {
  cursor: auto !important;
}

/*login background card */
.login-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 443px;
  /* min-height: 600px; */
  max-height: 900px;
  background: white;
  border-radius: 16px;
  padding: 44px;
}

.login-card label {
  font-weight: normal !important;
  color: gray !important;
}

/* Disable arrows on input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.displayForm .field > span,
.uppserCaseLabel,
.form .field > label {
  font-weight: normal !important;
  font-size: 12px !important;
  display: block !important;
  margin: 0 0 0.28571429rem 0 !important;
  text-transform: uppercase !important;
}

.displayForm .field span:not(.visit-label):not(.user-type-label),
.form .field > label {
  color: var(--color-butelj) !important;
}

.displayLabel {
  font-weight: bold;
  display: block;
  margin: 0 0 0.28571429rem 0;
}

.displayForm .field {
  margin: 0 0 1em;
}

.displayForm.noWrap .field {
  text-wrap: nowrap;
  overflow-x: auto;
}

.textWrap {
  text-wrap: wrap !important;
}

.textWrapBalance {
  text-wrap: balance !important;
}

.radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-container .text {
  flex-grow: 1;
  text-align: left;
}

.radio-container .radio-button {
  flex-shrink: 0;
}

.companyCardContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100% !important; /* set the height of the parent div to 100% */
  flex-flow: column !important;
}

.ui.scrolling.modal.transition.visible.active {
  margin-top: 50px !important;
}

/* Override semantic ui modal z-index  */
.ui.dimmer {
  z-index: 6 !important;
}

/* .displayForm .field span {

} */

.SingleDatePicker_picker {
  z-index: 3 !important;
}

.SingleDatePickerInput__withBorder {
  border: 0 !important;
}

.DateRangePickerInput {
  display: flex !important;
  align-items: center;
}

.DateRangePickerInput .DateInput {
  width: auto;
}

.rc-time-picker-input {
  height: unset !important;
}

.languagePicker {
  min-width: 140px;
}

/* Override semantic ui tab  */
.ui.tabular.menu .active.item {
  font-weight: normal;
}

.ui.attached.tabular.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

.ui.attached.tabular.menu,
.ui.attached.tabular.menu a.item,
.ui.bottom.attached.segment.active.tab {
  border: 0;
}

/* Override semantic ui tab content */
.active.tab.attached.segment {
  max-height: calc(100vh - 140px);
  overflow: auto;
}

/* Override toggle label color */
.ui.toggle.checkbox label,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: var(--color-butelj) !important;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centeredAndPadded {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#__react-alert__ div div div {
  width: auto !important;
}

.textLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--color-cactus);
  cursor: pointer;
}

.textLink:hover {
  text-decoration: underline;
  color: var(--color-butelj);
}

.whiteLink {
  color: #fff !important;
}
.whiteLink:hover {
  color: #ddd !important;
}

.paddedBottom {
  padding-bottom: 10px;
}

.hiddenInputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.opacity-0-5 {
  opacity: 0.5;
}

div.ui.steps .step:after {
  z-index: 1;
}

td.tableCellWrap {
  padding: 1px !important;
  word-wrap: break-word !important;
}

.commentIcon {
  background-image: url("../images/comment.png");
}

.negativeIcon {
  color: #de836f !important;
}

.noBorder {
  border: none !important;
  box-shadow: none !important;
}

.shadowOnHover {
  transition: box-shadow 0.6s linear;
}

.shadowOnHover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19) !important;
}

.ui.segment.filled {
  /* background-color: #F8F9FA; */
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.ui.segment.noFillAndBorder {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.ui.segment.highlighted {
  border: 2px solid var(--color-cactus) !important;
}

.ui.tabular.menu .item.highlighted {
  border: 2px solid var(--color-cactus) !important;
  border-bottom: none !important;
  color: #fff;
  background-color: var(--color-cactus);
  font-size: 16px;
  font-weight: 400;
  font-family: poppins;
  padding: 0 20px 0 20px !important;
  border-radius: 10px 10px 0 0 !important;
}

.ui.steps.filled .step.completed .title {
  color: #fff;
}

.ui.steps.filled .step.completed .description {
  color: #fff;
}

.ui.steps.filled .step.completed {
  /* background: #75dfc9; */
  background: var(--color-cactus);
}

.ui.steps.filled .step.active {
  background: #f3f4f5;
}

.ui.steps.filled .step:after {
  width: 4.45em;
  height: 4.45em;
}

.ui.steps.filled.thin .step:after {
  width: 1.14em;
  height: 1.14em;
}

.ui.vertical.steps .active.step:after {
  display: none;
}

.ui.vertical.steps .active.step:last-child:after {
  display: none;
}

.ui.steps.filled .step.completed:after {
  /* background: #75dfc9; */
  background: var(--color-cactus);
}

.step.completed:before,
.ui.steps.filled .step.completed > .icon:before {
  /* color: #000; */
  color: #fff;
}

.ui.primary.button {
  background: var(--color-cactus);
  color: #fff;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: var(--color-butelj);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
  background-color: var(--color-butelj);
}

.ui.secondary.button {
  background: #fff;
  color: var(--color-cactus);
  border: solid 1px var(--color-cactus) !important;
  border-radius: 10em;
}

/* .ui.basic.button {
  border-color: var(--color-cactus);
  border: solid var(--color-cactus) !important;
  border-width: 2px !important;
  border-radius: 10em;
} */

.ui.secondary.button:hover,
.ui.secondary.buttons .button:hover {
  background: var(--color-fogmassa);
  color: var(--color-cactus);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.ui.negative.button {
  background: #de836f;
}

.ui.negative.button:hover,
.ui.negative.buttons .button:hover {
  background: #bf6155;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.ui.button {
  border-radius: 10em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.ui.button.rectangular {
  border-radius: 0;
}

.ui.button.smallRadius {
  border-radius: 4px;
}

.ui.button > .icon {
  opacity: 1 !important;
}

.ui.button > .icon:not(.button) .noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.ui.grid.narrow > .column:not(.row) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.chartHeader {
  font-size: 16px;
  font-weight: 400;
  font-family: "Popins", sans-serif;
  color: var(--color-butelj);
}

.chartLegendContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.chartLegendContainer button {
  font-size: 0.8em;
  border: none;
  background: none;
}

li.p::before,
li.c::before,
li.pc::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-right: 1ch;
  vertical-align: middle;
}

li.p::before {
  background-color: var(--color-propcomp);
}

li.c::before {
  background-color: var(--color-contractor);
}

li.pc::before {
  background: linear-gradient(
    45deg,
    var(--color-propcomp) 50%,
    var(--color-contractor) 50%
  );
}

li.no-bullet {
  list-style-type: none;
}

.release-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
}

.release-legend-item::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-right: 5px;
}

.release-legend-item.p::before {
  background-color: var(--color-propcomp);
}

.release-legend-item.c::before {
  background-color: var(--color-contractor);
}

.release-legend-item.pc::before {
  background: linear-gradient(
    45deg,
    var(--color-propcomp) 50%,
    var(--color-contractor) 50%
  );
}

.release-highlight {
  background-color: var(--color-fogmassa);
  border: 1px solid var(--color-cactus);
  padding: 1rem;
  border-radius: 10px;
}

.release-image {
  width: 100%;
  height: auto;
}

.integration-logo {
  max-width: 200px;
}

.scrollable-content {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.mention {
  background-color: var(--color-butelj) !important;
  color: #fff !important;
  border-radius: 500em !important;
  line-height: 1em !important;
  font-size: 0.85714286rem !important;
  margin-left: 2px !important;
}

.templateTag {
  margin-left: 2px !important;
  color: #fff !important;
  /* background-color: #000 !important; */
}

.ant-steps-item-container {
  padding: 0;
  display: flex;
  justify-content: center;
  width: fit-content;
}

/* div.ant-steps-item-icon {
  background-color: #639780 !important;
} */

.rbc-event {
  flex-flow: column !important;
}

/* SyncFusion components */
.e-work-cells {
  background-color: transparent !important;
}

.e-footer-container .e-today {
  visibility: hidden;
}

.e-schedule .e-work-cells {
  background: #f9fafb !important;
  border-color: #e5e7eb !important;
  border-style: solid !important;
  border-width: 0 0 1px 1px !important;
  padding: 0 !important;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  border-bottom-color: #e5e7eb !important;
}

span.e-header-date {
  display: none;
}

.e-current-time {
  font-size: 9px !important;
  bottom: -2px !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .button {
    display: none !important;
  }

  body {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: none;
  }

  html,
  body {
    height: "auto";
  }

  .sub.header {
    display: none !important;
  }

  .main-content {
    margin: 0;
  }

  .ui.padded {
    padding: 0 !important;
  }

  .ui.segment.highlighted {
    border: none !important;
  }
}

@page {
  size: a4 portrait;
  margin: 1em;
}

.passWrapper {
  position: relative;
}

#password {
  padding-right: 40px; /* Make room for the icon */
}

.show-password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.ui.buttons .or:before {
  content: "";
}

.ui.buttons .split {
  width: 5px;
}

.deleteLinkButton {
  color: #de836f;
}

.deleteLinkButton:hover {
  color: #bf6155;
}

.infoLinkButton {
  color: var(--color-cactus);
}

/* Ant design */

.ant-steps {
  margin-left: 10px !important;
}

.ant-steps-item-finish .ant-steps-icon {
  color: var(--color-cactus) !important;
}
.ant-steps-item-title:after {
  background-color: #fff !important;
  border: solid var(--color-cactus) !important;
  border-width: 1px !important;
}

.currentStep .ant-steps-item-title:after {
  border: 0 !important;
}

.ant-steps-navigation .ant-steps-item:after {
  left: 98% !important;
}

iframe,
object,
embed {
  max-width: 100%;
}

.searchResultContainer {
  display: flex;
  flex-wrap: wrap;
}

.searchResultContainer .resultItem {
  /* flex: auto; */
  margin-right: 2em;
  display: flex;
}

.searchResultContainer .resultItem span {
  flex: auto;
  margin-left: 5px;
}

.searchResultContainer .resultItem span:after {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: -14px -10px 0 4px;
  border: 1px solid var(--color-cactus);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: "";
}

.ui.sortable.table thead th:empty {
  pointer-events: none;
}
.ui.sortable.table thead th:empty:after {
  display: none;
}

.ui.action.input:not([class*="left action"]) > input:focus {
  border-color: var(--color-cactus) !important;
}

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus {
  border-color: var(--color-butelj) !important;
}

.activeSearch i.clearSearch {
  cursor: pointer !important;
  position: absolute !important;
  text-align: center !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  left: 0 !important;
  margin: 0 !important;
  opacity: 0.5 !important;
  cursor: pointer !important;
  z-index: 999 !important;
}

.activeSearch input {
  padding-left: 25px !important;
}

.ui.modal .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}
          
.ui.modal .ui.loader:after {
  border-color: #767676 transparent transparent;
}

.ui.action.input:not([class*="left action"]) > input {
  border: 1px solid rgba(34, 36, 38, 0.3) !important;
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
}

.ui.inverted.menu {
  background: var(--color-butelj);
  z-index: 999;
}

.ui.vertical.menu .menu .item {
  padding: 0.7em;
  border-radius: 99px 0 0 99px !important;
}

.ui.inverted.menu .active.item {
  border-radius: 999px 0 0 999px !important;
  background-color: var(--color-fogmassa);
  color: var(--color-butelj) !important;
}

.ui.vertical.inverted.menu .menu .link.item {
  color: #fff;
  font-size: 14px;
  margin-left: 20px;
}

.ui.inverted.menu .ui.inverted.menu .link.item:hover,
.ui.link.inverted.menu .item:hover {
  background-color: var(--color-cactus);
  color: #fff !important;
  border-radius: 999px 0 0 999px !important;
}

.ui.menu {
  font-family: "Poppins", sans-serif;
}

.menu.centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu .item.no-divider:before {
  background: none !important;
}

.accordionHeader {
  font-size: 24px;
  margin: 0;
}

.registeredCompanyCornerLabel {
  border-color: var(--color-cactus) !important;
}

.flexNone {
  flex: none !important;
}

.butelj {
  background: var(--color-butelj) !important;
}

.categorySegmentContainer {
  margin-top: 10px;
  max-width: 400px;
}

.orderSubOrdersContainerTenant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.categorySegmentContainerTenant {
  margin-top: 10px;
  max-width: 400px;
  width: 100%;
}

.categoryCardsTenant {
  display: flex;
  justify-content: center;
  width: 100%;
}

.categoryClickable {
  cursor: pointer;
  border-radius: 10em;
}

.categoryClickable:hover {
  color: var(--color-butelj);
  border: solid 1px var(--color-cactus) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.categorySortSelect {
  right: 0px;
  position: absolute;
  top: 14px;
  right: 14px;
}

.ui.info.message {
  background-color: var(--color-fogmassa) !important;
  border: solid 1px var(--color-butelj) !important;
  color: var(--color-butelj) !important;
  box-shadow: none !important;
}

/* .ui.error.message {
  background-color: var(--color-fogmassa) !important;
  border: solid 1px var(--color-butelj) !important;
  color: var(--color-butelj) !important;
  box-shadow: none !important;
} */

.error-message {
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.error-icon {
  color: var(--color-korall);
  margin-right: 8px; /* adjust this value as needed */
  font-size: 1.2em; /* adjust this value as needed */
}

.error-text {
  color: var(--color-korall);
  font-style: italic;
}

.error-border {
  border: solid 1px var(--color-korall) !important;
}

.ui.noData.message {
  margin: 0 0 1em 0;
  display: block;
}

.circleButton {
  background-color: #fff !important;
  border: solid 1px var(--color-cactus) !important;
}

.circleButton i {
  color: var(--color-cactus);
}

.circleButton:hover {
  background-color: var(--color-fogmassa) !important;
}

.orderSummaryText span {
  color: var(--color-butelj);
  text-transform: uppercase;
  margin-right: 15px;
}

.companyProperties li {
  color: var(--color-butelj);
  list-style-type: none;
  font-size: 12px;
}

.ui.label {
  font-weight: 400 !important;
  margin-bottom: 2px !important;
}

.ui.image.label {
  margin-bottom: 3px !important;
}

.CalendarDay__selected_span,
.CalendarDay__default:hover,
.CalendarDay__hovered_span {
  background: var(--color-cactus);
  color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active {
  background: var(--color-butelj);
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: var(--color-cactus) !important;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: grey;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--color-cactus) !important;
}

.serviceLabel {
  background-color: transparent !important;
  color: #a56a43;
  color: #bf6155;
  color: #c8e28f;
  color: #d88a55;
  color: #de836f;
  color: #edcc66;
  color: #ffc477;
}

.categoryCard:hover {
  border: solid 1px var(--color-cactus) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19) !important;
}

.categorySearch .input {
  width: 100% !important;
  max-width: 400px;
}

.categorySearch input:focus {
  border-color: var(--color-cactus) !important;
}

.ui.form .required.field > .checkbox:after,
.ui.form .required.field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.fields:not(.grouped) > .field > label:after {
  color: #de836f !important;
}

.notRequired:after {
  content: none !important;
}

.ui.info.circle.icon {
  margin-left: 5px;
  background-color: transparent;
  color: var(--color-butelj);
}

.ui.icon.button.minimalistic {
  border: 0;
  background-color: transparent;
  color: var(--color-butelj);
}

.ui.multiple.dropdown > .label {
  background-color: var(--color-butelj) !important;
  color: #fff !important;
}

.ui.basic.table th {
  color: var(--color-cactus);
  font-weight: 400;
  text-transform: uppercase;
}

.ui.basic.table td.span-uppercase > span {
  color: var(--color-cactus);
  font-weight: 400;
  text-transform: uppercase;
}

.ui.sortable.table thead th {
  color: var(--color-cactus);
  font-weight: 400;
  text-transform: uppercase;
}

.ui.sortable.table thead th.sorted {
  background-color: #c9e3dd;
  color: var(--color-cactus);
}

.ui.table.increasedPadding td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ui.basic.table {
  border: 0 !important;
}

.ui.basic.striped.table tbody tr:nth-child(1n),
.ui.striped.table tbody tr:nth-child(1n),
.ui.basic.table.striped-double tbody tr:nth-child(4n + 1),
.ui.basic.table.striped-double tbody tr:nth-child(4n + 2) {
  background-color: var(--color-fogmassa) !important;
}

.ui.basic.striped.table tbody tr:nth-child(2n),
.ui.striped.table tbody tr:nth-child(2n),
.ui.basic.table.striped-double tbody tr:nth-child(4n + 3),
.ui.basic.table.striped-double tbody tr:nth-child(4n + 4) {
  background-color: #fff !important;
}

/* Override semantic ui modal z-index  */
.ui.dimmer {
  z-index: 2 !important;
}


.ui.table tr.hoverable:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  z-index: 2;
}

.ui.table tr.attentionRequired {
  background-color: var(--color-fogmassa);
}

.ui.table tr.hoverable.attentionRequired:hover {
  background-color: rgba(247, 245, 241, 0.7);
}

.ui.table.lines:not(.noborders) tr,
.ui.table.lines:not(.noborders):not(.no-cell-borders) td {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.ui.table.noborders th,
.ui.table.noborders tr,
.ui.table.noborders td {
  border: 0 !important;
}

.ui.table.no-cell-borders th,
.ui.table.no-cell-borders td {
  border-left: none !important;
  border-right: none !important;
}

/* .ui.table tr.attentionRequired,
.ui.table tr.attentionRequired td,
.ui.table tr.attentionRequired td div,
.ui.table tr.attentionRequired td div span {
    font-weight: bold !important;
} */

.webkit-fill {
  min-width: -webkit-fill-available;
}

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block !important;
}

.displayFlex {
  display: flex !important;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.display-flex-row {
  display: flex !important;
  flex-flow: row !important;
}

.display-flex-column {
  display: flex !important;
  flex-flow: column !important;
}

.display-flex-row-wrap {
  display: flex !important;
  flex-flow: row wrap !important;
}

.flex {
  display: flex;
  flex: 1 1 0;
}
.gap-3 {
  gap: 3px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}

.align-content-center {
  align-content: center;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-space-between {
  justify-content: space-between;
}

.input-container-fault-report {
  max-width: 400px;
}

.celebrate-report-image {
  max-width: 250px;
  min-height: 220px;
}

.button-fault-report {
  padding: 13px 22px !important;
}

.display-content {
  display: contents;
}

.bg-propcomp {
  background-color: var(--color-propcomp) !important;
}

.bg-contractor {
  background-color: var(--color-contractor) !important;
}

.bg-sjogras {
  background-color: var(--color-sjogras) !important;
}

.bg-cactus {
  background-color: var(--color-cactus) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-grey {
  background-color: var(--color-grey) !important;
}

.bg-korall {
  background-color: var(--color-korall) !important;
}

.bg-fogmassa {
  background-color: var(--color-fogmassa) !important;
}

.bg-sandsten {
  background-color: var(--color-sandsten) !important;
}

.bg-blekros {
  background-color: var(--color-blekros) !important;
}

.bg-ockra {
  background-color: var(--color-ockra) !important;
}

.bg-ockra-light {
  background-color: var(--color-ockra-light) !important;
}

.bg-skog-light {
  background-color: var(--color-skog-light) !important;
}

.bg-grafit-light {
  background-color: var(--color-grafit-light) !important;
}

.bg-tegel-light {
  background-color: var(--color-tegel-light) !important;
}

.bg-ametist-light {
  background-color: var(--color-ametist-light) !important;
}

.bg-safir-light {
  background-color: var(--color-safir-light) !important;
}

.bg-mossa-light {
  background-color: var(--color-mossa-light) !important;
}

.bg-rubin-light {
  background-color: var(--color-rubin-light) !important;
}

.bg-shadow-light {
  background-color: var(--color-shadow-light) !important;
}

.bg-period-now {
  background-color: var(--color-period-now) !important;
}

.bg-period-previous {
  background-color: var(--color-period-previous) !important;
}

.color-white {
  color: white !important;
}

.color-cactus {
  color: var(--color-cactus) !important;
}

.color-korall {
  color: var(--color-korall) !important;
}

.color-butelj {
  color: var(--color-butelj) !important;
}

.color-ockra {
  color: var(--color-ockra) !important;
}

.color-skog {
  color: var(--color-skog) !important;
}

.color-grafit {
  color: var(--color-grafit) !important;
}

.color-tegel {
  color: var(--color-tegel) !important;
}

.color-ametist {
  color: var(--color-ametist) !important;
}

.color-safir {
  color: var(--color-safir) !important;
}

.color-mossa {
  color: var(--color-mossa) !important;
}

.color-rubin {
  color: var(--color-rubin) !important;
}

.color-shadow {
  color: var(--color-shadow) !important;
}

.color-sandsten {
  color: var(--color-sandsten) !important;
}

.color-blekros {
  color: var(--color-blekros) !important;
}

.round-borders {
  border-radius: 100px !important;
  overflow: hidden;
}

.width-100 {
  width: 100% !important;
}

.width-80 {
  width: 80% !important;
}

.width-50 {
  width: 50% !important;
}

.width-30 {
  width: 30%;
}

.width-40-px {
  width: 40px;
}

.width-25-px {
  width: 25px;
}

.height-100 {
  height: 100% !important;
}

.height-40-px {
  height: 40px !important;
}

.height-25-px {
  height: 25px;
}

.margin-0-5 {
  margin: 0.5rem !important;
}

.mh-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-0-5 {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-0-5 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-0-5 {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-0-5 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}
.p-0-5 {
  padding: 0.5rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.b-solid-1 {
  border: solid 1px !important;
}

.b-radius-18 {
  border-radius: 18px !important;
}

.b-radius-50 {
  border-radius: 50%;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.text-small {
  font-size: 12px;
  font-weight: 400;
}

.text-medium {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.text-medium-large {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.text-large {
  font-size: 1.43rem !important;
  font-weight: 400 !important;
}

.text-xlarge {
  font-size: 1.8rem !important;
  font-weight: 400 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-decoration-underline-hover-span:hover span {
  text-decoration: underline !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.focus-border-cactus:focus {
  border: solid 1px var(--color-cactus) !important;
}

.tenant-fault-report-btn-icon {
  height: 14px !important;
  width: 8px !important;
  font-size: 18px !important;
}

.file-upload-thumbnail {
  position: relative;
}

.file-upload-thumbnail-delete {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.file-upload-thumbnail-delete .icon {
  color: white !important;
  font-size: 16px !important;
  display: contents;
}

.ui.selection.active.dropdown .menu,
.ui.active.visible.fluid.search.selection.dropdown {
  border-color: var(--color-cactus) !important;
}

.searchWrapper {
  border: 0 !important;
  border-radius: 0 !important;
  min-height: 0 !important;
  padding: 0 !important;
}

.overflow-dropdown .divider.text {
  text-overflow: ellipsis;
  max-width: 100%;
}

.progress-bar-step {
  height: 4px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.show-details-button {
  border: none;
  background: none;
  font-family: "POPPINS";
  padding: 0;
  margin-top: 1rem;
  text-decoration: none;
  cursor: pointer;
}

.show-details-button:hover {
  text-decoration: underline;
}

.show-details-link {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.ui.header {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.ui.feed > .event > .label {
  width: 50px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.ui.feed > .event > .label img {
  border-radius: 8px !important;
  max-height: 50px;
}

h2.ui.header {
  font-size: 1.6669rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.forceSmallButton {
  max-width: 32px;
}

.columnDivider {
  border-left: 2px solid #ccc !important;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

@media only screen and (min-width: 768px) {
  .mt-2-desktop {
    margin-top: 2rem !important;
  }

  .p-3-desktop {
    padding: 3rem !important;
  }

  .pr-2-desktop {
    padding-right: 2rem !important;
  }

  .width-20-desktop {
    width: 20% !important;
  }

  .width-30-desktop {
    width: 30% !important;
  }

  .width-50-desktop {
    width: 50% !important;
  }

  .display-flex-row-desktop {
    display: flex !important;
    flex-flow: row !important;
  }

  .margin-none-desktop {
    margin: 0 !important;
  }

  .mobile-only {
    display: none !important;
  }
}

.ui.star.rating .active.icon {
  color: var(--color-butelj) !important;
  text-shadow: none !important;
}

@media (min-width: 768px) {
  .ui.vertical.menu.sidebar-menu {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 15rem !important;
    z-index: 5;
  }
  .main-content {
    margin-left: 15rem;
  }
  .sidebar-menu-toggler {
    display: none !important;
  }

  .ui.modal {
    margin-left: 215px !important;
  }
}
/*# sourceMappingURL=style.css.map */

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large monitor only"]:not(.mobile),
  [class*="widescreen monitor only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }

  .mobile-only {
    display:inline !important;
  }

  .ui.container {
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }

  .ui.stackable.grid > .row > .column.wide.noPaddingMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ui.stackable.grid > .column.wide.noPaddingMobile {
    padding: 0 !important;
  }

  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) button {
    margin-bottom: 5px !important;
  }

  .ui.table:not(.unstackable) td:first-child,
  .ui.table:not(.unstackable) th:first-child {
    font-weight: 400 !important;
  }

  input,
  select,
  textarea {
    font-size: 16px !important;
  }

  .ant-steps-navigation .ant-steps-item:after {
    left: 85% !important;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: normal;
    gap: 1rem;
  }

  .mobile-margin-top {
    margin-top: 15px !important;
  }

  .mobile-padding-0-5 {
    padding: 0.5rem !important;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .button {
    display: none !important;
  }

  body {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: none;
  }

  html,
  body {
    height: "auto";
    font-size: 12px;
    line-height: 14px;
  }

  .sub.header {
    display: none !important;
  }

  .main-content {
    margin: 0;
  }

  .ui.padded {
    padding: 0 !important;
  }

  .ui.grid {
    margin: 0 !important;
  }

  .ui.grid > .row {
    padding: 0 !important;
  }

  .ant-steps {
    flex-direction: row !important;
  }

  .ant-steps-navigation .ant-steps-item:after {
    display: none !important;
  }

  .ant-steps span {
    /* display: none !important; */
    font-size: 14px !important;
  }

  .ant-steps-item-title {
    font-size: 14px !important;
  }

  .ant-steps-item-icon {
    display: none !important;
  }

  .noHeightPaddingPrint {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .headerSpacing {
    height: 4px;
  }

  .spacing-m {
    height: 8px;
  }
  .spacing-lg {
    height: 12px;
  }

  .spacing-xs {
    height: 2px;
  }

  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

.white-space-nowrap {
  white-space: nowrap !important;
}

@media (max-width: 1000px) {
  .white-space-nowrap.no-mobile {
    white-space: inherit !important;
  }
}

label[for] {
  cursor: pointer !important;
}

.break-page {
  page-break-before: always;
  word-break: break-all;
}

/* ProgressBar.css */
.progress-bar {
  display: flex;
  align-items: center;
}

.step-container {
  display: flex;
  align-items: center;
  position: relative;
}

.progress-step {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #b9b7b3;
}

.progress-step.completed {
  background-color: #639780;
  color: white;
}

.progress-line {
  height: 4px;
  background-color: #b9b7b3;
  flex-grow: 1;
  z-index: 0;
  margin-left: -15px;
  margin-right: -15px;
}

.progress-line.completed {
  background-color: #639780;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

.status-positive {
  background-color: var(--color-white) !important;
  border: 2px solid var(--color-cactus) !important;
  color: var(--color-cactus) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.status-negative {
  background-color: var(--color-white) !important;
  border: 2px solid var(--color-korall) !important;
  color: var(--color-korall) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.customized-table .customized-table-row-even {
  background-color: var(--color-white) !important;
}
.customized-table .customized-table-row-odd {
  background-color: var(--color-fogmassa) !important;
}

/* The even and odd CSS classes are reversed for countdown compared to the other rows CSS classes in the customized-table. */
.countdown-odd {
  background-color: var(--color-white) !important;
}
.countdown-even {
  background-color: var(--color-fogmassa) !important;
}

.quill {
  max-width: 100% !important;
}

.ql-editor {
  font-size: 16px !important;
}

#customized-ql-editor .ql-editor {
  padding-right: 0 !important;
  padding-left: 0 !important;
  display: flex;
  flex-direction: column;
}

.avatar {
  margin: 0 !important;
}

.meta-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customized-metadata {
  margin-left: 0 !important;
}

.customized-comment {
  max-width: 100%;
  gap: 0.5rem;
}

.ui.grid.container.customized-messages-container {
  margin: 0;
  padding: 0;
  display: flex;
  max-width: 100% !important;
}

.followup-container {
  width: 100%;
}

.latest-events-icon {
  height: 35px;
  padding: 2px;
  margin-right: 15px;
}
 
.flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-20 {
  max-width: 20px;
}

/* Customized css for OrderTable on small devices */
.responsive-ordertable-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-content: space-between;
}

.responsive-ordertable-row td {
  border: none !important;
}

@media (max-width: 1200px) {
  .responsive-ordertable-row {
    grid-template-columns: repeat(4, 1fr);
  }

  /* First table row */
  .responsive-ordertable-row > *:nth-child(1),
  .responsive-ordertable-row > *:nth-child(2),
  .responsive-ordertable-row > *:nth-child(3),
  .responsive-ordertable-row > *:nth-child(4) {
    grid-column: span 1;
  }

  /* Second table row */
  .responsive-ordertable-row > *:nth-child(5),
  .responsive-ordertable-row > *:nth-child(6),
  .responsive-ordertable-row > *:nth-child(7) {
    grid-column: span 1;
  }
}

@media (max-width: 890px) {
  .responsive-ordertable-row {
    grid-template-columns: repeat(3, 1fr);
  }

  /* First table row */
  .responsive-ordertable-row > *:nth-child(1),
  .responsive-ordertable-row > *:nth-child(2),
  .responsive-ordertable-row > *:nth-child(3)  {
    grid-column: span 1;
  }

  /* Second table row */
  .responsive-ordertable-row > *:nth-child(4) {
    grid-column: span 3;
  }

  /* Third table row */
  .responsive-ordertable-row > *:nth-child(5),
  .responsive-ordertable-row > *:nth-child(6),
  .responsive-ordertable-row > *:nth-child(7) {
    grid-column: span 1;
  }
}


@media (max-width: 630px) {
  .responsive-ordertable-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 525px) {
  .responsive-ordertable-row {
    grid-template-columns: repeat(2, 1fr);
  }

  /* First table row */
  .responsive-ordertable-row > *:nth-child(1),
  .responsive-ordertable-row > *:nth-child(2) {
    grid-column: span 1;
  }

  /* Second table row */
  .responsive-ordertable-row > *:nth-child(3) {
    grid-column: span 2;
  }

  /* Third table row */
  .responsive-ordertable-row > *:nth-child(4) {
    grid-column: span 2;
  }

  /* Fourth table row*/
  .responsive-ordertable-row > *:nth-child(5),
  .responsive-ordertable-row > *:nth-child(6),
  .responsive-ordertable-row > *:nth-child(7) {
    grid-column: span 1;
  }
}

/* Customized css for the confirm modals on small devices - or else the box will be at the top for some reason */
@media (max-height: 700px) {
  .customize-confirm {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 480px) {
  .overview-order-title-mobile {
    font-size: 15px !important;
  }

  .overview-order-count-mobile {
    font-size: 20px !important;
  }
}
